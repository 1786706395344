/*
  	Flaticon icon font: Flaticon
  	Creation date: 01/08/2019 09:08
  	*/

@font-face {
    font-family: 'Flaticon';
    src: url('..//fonts/Flaticon.eot');
    src: url('..//fonts/Flaticon.eot?#iefix') format('embedded-opentype'),
        url('..//fonts/Flaticon.woff2') format('woff2'), url('..//fonts/Flaticon.woff') format('woff'),
        url('..//fonts/Flaticon.ttf') format('truetype'), url('..//fonts/Flaticon.svg#Flaticon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: 'Flaticon';
        src: url('..//fonts/Flaticon.svg#Flaticon') format('svg');
    }
}

[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
    font-family: 'Flaticon', sans-serif;
    font-size: inherit;
    font-style: normal;
}

.flaticon-report:before {
    content: '\f100';
}
.flaticon-clock:before {
    content: '\f101';
}
.flaticon-call:before {
    content: '\f102';
}
.flaticon-tourism:before {
    content: '\f103';
}
.flaticon-process:before {
    content: '\f104';
}
.flaticon-data:before {
    content: '\f105';
}
.flaticon-global:before {
    content: '\f106';
}
.flaticon-system:before {
    content: '\f107';
}
.flaticon-padlock:before {
    content: '\f108';
}
.flaticon-process-1:before {
    content: '\f109';
}
.flaticon-data-1:before {
    content: '\f10a';
}
.flaticon-work:before {
    content: '\f10b';
}
.flaticon-data-analytics:before {
    content: '\f10c';
}
.flaticon-chart:before {
    content: '\f10d';
}
.flaticon-algorithm:before {
    content: '\f10e';
}
.flaticon-data-2:before {
    content: '\f10f';
}
.flaticon-clock-1:before {
    content: '\f110';
}
.flaticon-microchip:before {
    content: '\f111';
}
.flaticon-server:before {
    content: '\f112';
}
.flaticon-24h:before {
    content: '\f113';
}
.flaticon-global-1:before {
    content: '\f114';
}
.flaticon-business-and-finance:before {
    content: '\f115';
}
.flaticon-space:before {
    content: '\f116';
}
.flaticon-information:before {
    content: '\f117';
}
.flaticon-privacy:before {
    content: '\f118';
}
.flaticon-alert:before {
    content: '\f119';
}
.flaticon-online-library:before {
    content: '\f11a';
}
.flaticon-info:before {
    content: '\f11b';
}
.flaticon-interaction:before {
    content: '\f11c';
}
.flaticon-mobile-app:before {
    content: '\f11d';
}
.flaticon-code:before {
    content: '\f11e';
}
.flaticon-developer:before {
    content: '\f11f';
}
.flaticon-intelligent:before {
    content: '\f120';
}
.flaticon-content:before {
    content: '\f121';
}
.flaticon-laptop:before {
    content: '\f122';
}
.flaticon-web:before {
    content: '\f123';
}
.flaticon-content-writing:before {
    content: '\f124';
}
.flaticon-computer:before {
    content: '\f125';
}
.flaticon-computer-1:before {
    content: '\f126';
}
.flaticon-graphic-design:before {
    content: '\f127';
}
.flaticon-analysis:before {
    content: '\f128';
}
.flaticon-bar-chart:before {
    content: '\f129';
}
.flaticon-analytics:before {
    content: '\f12a';
}
.flaticon-ui:before {
    content: '\f12b';
}
.flaticon-resume:before {
    content: '\f12c';
}
.flaticon-taxi:before {
    content: '\f12d';
}
.flaticon-online-shop:before {
    content: '\f12e';
}
.flaticon-smartphone:before {
    content: '\f12f';
}

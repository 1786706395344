@import url('https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700&display=swap');

// colors

$skin-color: #2d4a8a;
$darkgrey-color: #263045;
$grey-color: #f7f9fe;
$white-color: #fff;
$black-color: #000;

$font-stack: 'Poppins',sans-serif;
$heading-font-stack: 'Poppins',sans-serif;
$body-font: 14px;
$body-font-color: #6e6e6e;